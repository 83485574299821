import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';
import {
    isFasterForwardSeekButton,
    isFasterRewindSeekButton,
    isForwardSeekButton,
    isPauseButton,
    isPlayButton,
    isPlayPauseButton,
    isRewindSeekButton,
    isStopButton,
} from 'src/utilities/keys';
import useKeyPress from './keyPress/keyPress.controller';
import { KEY_PRESS_RANKS } from './keyPress/keyPressControllerRanks';

interface Handlers {
    onKeyPress?: (event?: KeyboardEvent) => void;
    onPlayPress?: (event?: KeyboardEvent) => void;
    onPausePress?: (event?: KeyboardEvent) => void;
    onPlayPausePress?: (event?: KeyboardEvent) => void;
    onForwardSeekPress?: (event?: KeyboardEvent) => void;
    onFasterForwardSeekPress?: (event?: KeyboardEvent) => void;
    onRewindSeekPress?: (event?: KeyboardEvent) => void;
    onFasterRewindSeekPress?: (event?: KeyboardEvent) => void;
}

const useMediaButtons = (handlers: Handlers) => {
    const {
        onKeyPress,
        onForwardSeekPress,
        onFasterForwardSeekPress,
        onRewindSeekPress,
        onFasterRewindSeekPress,
    } = handlers || {};
    const navigate = useNavigate();
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const Player = getActivePlayer();
            onKeyPress?.(event);
            switch (true) {
                case isPlayButton(event):
                    Player.play();
                    return true;
                case isPauseButton(event):
                    Player.pause();
                    return true;
                case isPlayPauseButton(event):
                    if (Player.isPlaying()) {
                        Player.pause();
                    } else {
                        Player.play();
                    }
                    return true;
                case isForwardSeekButton(event):
                    onForwardSeekPress?.();
                    return true;
                case isFasterForwardSeekButton(event):
                    onFasterForwardSeekPress?.();
                    return true;
                case isRewindSeekButton(event):
                    onRewindSeekPress?.();
                    return true;
                case isFasterRewindSeekButton(event):
                    onFasterRewindSeekPress?.();
                    return true;
                case isStopButton(event):
                    Player.pause();
                    navigate(-1);
                    return true;

                default:
                    break;
            }
            return false;
        },
        [
            onKeyPress,
            onForwardSeekPress,
            onFasterForwardSeekPress,
            onRewindSeekPress,
            onFasterRewindSeekPress,
            navigate,
        ]
    );

    useKeyPress(handleKeyDown, KEY_PRESS_RANKS.braze);
};
export default useMediaButtons;
