import { CTVPlatforms } from 'src/config/config/config';
import { isPS4 } from 'src/utilities/ps4';
// import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
// import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
// import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
// import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
// import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
// import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
// import DrmModule from 'bitmovin-player/modules/bitmovinplayer-drm';
// import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
// import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
// import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
// import CryptoModule from 'bitmovin-player/modules/bitmovinplayer-crypto';
// import AdvertisingCoreModule from 'bitmovin-player/modules/bitmovinplayer-advertising-core';
// import AdvertisingBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-advertising-bitmovin';
// import AnalyticsModule from 'bitmovin-player/modules/bitmovinplayer-analytics';
import TizenModule from 'bitmovin-player/modules/bitmovinplayer-tizen';
import WebosModule from 'bitmovin-player/modules/bitmovinplayer-webos';
import PlayStation4 from 'bitmovin-player/modules/bitmovinplayer-playstation4';
import PlayStation5 from 'bitmovin-player/modules/bitmovinplayer-playstation5';
// import UIModule from 'bitmovin-player/modules/bitmovinplayer-ui';
// import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
// import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import Subtitles from 'bitmovin-player/modules/bitmovinplayer-subtitles';
// import Thumbnails from 'bitmovin-player/modules/bitmovinplayer-thumbnail';
import ThumbnailsImp from 'bitmovin-player/modules/bitmovinplayer-thumbnail-imp';

export type platformBitmovinModulesType = {
    [index in CTVPlatforms]?: any[];
};

export const commonBitmovinModules = [Subtitles, ThumbnailsImp];

export const platformBitmovinModules: platformBitmovinModulesType = {
    Foxtel: [TizenModule],
    LGTV: [WebosModule],
    SamsungTV: [TizenModule],
    PlayStation: isPS4() ? [PlayStation4] : [PlayStation5],
};
