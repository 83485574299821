import { PlayerAPI } from 'bitmovin-player';
import { ListBox, UIInstanceManager } from 'bitmovin-player-ui';
import { SubtitleSwitchHandler } from '../handlers/SubtitleSwitchHandler';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export class SubtitleListBox extends ListBox {
    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        new SubtitleSwitchHandler(player, this, uimanager);
    }
}
