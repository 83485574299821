import {
    BufferingOverlay,
    Button,
    Container,
    ControlBar,
    PlaybackTimeLabel,
    PlaybackTimeLabelMode,
    PlaybackToggleButton,
    PlayerUtils,
    QuickSeekButton,
    RootNavigationGroup,
    SeekBar,
    SeekBarLabel,
    SettingsToggleButton,
    Spacer,
    SpatialNavigation,
    SubtitleOverlay,
    TitleBar,
    UIContainer,
    UIVariant,
} from 'bitmovin-player-ui';

import { SettingsPanel } from './panels/settings';
import { BackButtonController } from 'src/controllers/keyPress/backButton.controller';
import { BACK_BUTTON_RANKS } from 'src/controllers/keyPress/backButtonControllerRanks';
import { CONFIG } from 'src/config/config';

import './styles/styles.scss';
import { LiveButton } from './components/LiveButton';
import { AssetLogo } from './components/AssetLogo';
import { ErrorMessageOverlay } from './components/ErrorMessageOverlay';
import { StreamKickOverlay } from './components/StreamKickOverlay';
import { ZombieKickOverlay } from './components/ZombieKickOverlay';
import { EPG } from './components/EPG';
import { EPGButton } from './components/EPGBUtton';
// import { EPGPanel } from './panels/epg';

const { playbackSettingsActive } = CONFIG;

export const getDefaultUI = () => {
    /*
        Subtitle Overlay
    */
    const subtitleOverlay = new SubtitleOverlay();

    /*
        Settings Panel
    */

    const { ui: settingsPanel, spatialNavigation: settingsPanelNavigationGroup } = SettingsPanel();

    /*
        Basic Controls
    */
    const titleBarContainer = new Container({
        components: [new TitleBar()],
        cssClasses: ['ui-title'],
    });

    /*
        Basic Controls
    */
    const playbackToggleButton = new PlaybackToggleButton();
    const rwButton = new QuickSeekButton({
        ariaLabel: '',
        seekSeconds: 10,
        text: '10',
        cssClasses: ['ui-quickseekbutton-rw-10'],
    });

    const ffButton = new QuickSeekButton({
        seekSeconds: -10,
        text: '10',

        cssClasses: ['ui-quickseekbutton-ff-10'],
    });

    const liveButton = new LiveButton();

    const settingsToggleButton = new SettingsToggleButton({
        settingsPanel: settingsPanel,
        autoHideWhenNoActiveSettings: true,
    });

    const channelLogo = new AssetLogo();
    const basicControlsContainer = new Container({
        components: [
            channelLogo,
            new Spacer(),
            rwButton,
            playbackToggleButton,
            ffButton,
            new Spacer(),
            liveButton,
        ],
        cssClasses: ['controlbar-bottom'],
    });
    if (playbackSettingsActive) {
        basicControlsContainer.addComponent(settingsToggleButton);
    }

    /*
        Seek Bar
    */
    const seekBar = new SeekBar({
        label: new SeekBarLabel(),
    });
    const seekBarContainer = new Container({
        components: [
            new PlaybackTimeLabel({
                timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
                cssClasses: ['text-right'],
                hideInLivePlayback: true,
            }),
            seekBar,
            new PlaybackTimeLabel({
                timeLabelMode: PlaybackTimeLabelMode.RemainingTime,
                cssClasses: ['text-right'],
                hideInLivePlayback: true,
            }),
        ],
        cssClasses: ['controlbar-top'],
    });

    const errorMessageOverlay = new ErrorMessageOverlay();
    const streamKickOverlay = new StreamKickOverlay();
    const zombieKickOverlay = new ZombieKickOverlay();

    let epgContainer = new EPG({
        onPopup: () => {
            controlBar.getDomElement().addClass('bmui-epg-visible');
        },
        onDismiss: () => {
            controlBar.getDomElement().removeClass('bmui-epg-visible');
        },
    });

    const epgTriggerButton = new EPGButton();
    const epgTriggerButtonContainer = new Container({
        components: [epgTriggerButton],
        cssClasses: ['controlbar-epg'],
    });

    epgTriggerButton.onClick.subscribe(() => epgContainer.toggleHidden());
    // epgTriggerButton.getDomElement().on('focus', () => epgContainer.toggleHidden());

    let controlBar = new ControlBar({
        components: [
            titleBarContainer,
            seekBarContainer,
            basicControlsContainer,
            epgTriggerButtonContainer,
            epgContainer,
        ],
    });

    const uiContainer = new UIContainer({
        hideDelay: CONFIG.playerUITimeout,

        components: [
            subtitleOverlay,
            new BufferingOverlay(),
            controlBar,
            settingsPanel,
            errorMessageOverlay,
            streamKickOverlay,
            zombieKickOverlay,
        ],
        hidePlayerStateExceptions: [
            PlayerUtils.PlayerState.Prepared,
            PlayerUtils.PlayerState.Paused,
            PlayerUtils.PlayerState.Finished,
            PlayerUtils.PlayerState.Idle,

            // PlayerUtils.PlayerState.Playing,
        ],
        hidden: true,
        cssClasses: ['ui-skin-ctv'],
    });

    /*
        Spatial Navigation
    */
    const rootNavigationGroup = new RootNavigationGroup(
        uiContainer,
        seekBar,
        playbackToggleButton,
        rwButton,
        ffButton,
        liveButton,
        settingsToggleButton,
        epgTriggerButton
    );

    const spatialNavigation = new SpatialNavigation(
        rootNavigationGroup,
        settingsPanelNavigationGroup,
        epgContainer.navigationGroup(),
        errorMessageOverlay.navigationGroup(),
        streamKickOverlay.navigationGroup(),
        zombieKickOverlay.navigationGroup()
    );

    let allowExit = false;
    const tOnAction = rootNavigationGroup.onAction;

    rootNavigationGroup.onAction = (a, d, preventDefault) => {
        // Navigate Back when back button is pressed once more after ui is hidden
        if (a !== 'select') {
            uiContainer.hideUi();
            if (allowExit) {
                window.history.back();
                preventDefault();
                return;
            } else {
                allowExit = true;
                setTimeout(() => {
                    allowExit = false;
                }, 3000);
            }
        }
        if (tOnAction) {
            tOnAction(a, d, preventDefault);
        }
    };

    // Back button Handling
    const bbc = new BackButtonController();
    uiContainer.onShow.subscribe(() => {
        const handleKeyDown = () => {
            const isShown = uiContainer.getDomElement().hasClass('bmpui-controls-shown');
            const isEPGShown = controlBar.getDomElement().hasClass('bmui-epg-visible');
            if (isEPGShown) {
                epgContainer.hide();
                return true;
            } else if (isShown) {
                uiContainer.hideUi();
                return true;
            }
            return false;
        };
        bbc.addNewCallbackToStack(handleKeyDown, BACK_BUTTON_RANKS.playerUiMain);
    });

    uiContainer.onHide.subscribe(() => {
        bbc.removeCallBack();
    });

    const uiVariant: UIVariant = {
        ui: uiContainer,
        spatialNavigation,
        condition: (context) => {
            return !(context.isAd && context.adRequiresUi);
        },
    };
    return uiVariant;
};
