import { PlayerAPI } from '../..';
import { UIInstanceManager } from 'bitmovin-player-ui';
import {
    ListItem,
    ListSelector,
    ListSelectorConfig,
} from 'bitmovin-player-ui/dist/js/framework/components/listselector';
import { getActivePlayer } from '../../utilities/player';

/**
 * Helper class to handle all video quality related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
export class PlaybackSpeedSwitchHandler {
    private player: PlayerAPI;
    private listElement: ListSelector<ListSelectorConfig>;
    private uimanager: UIInstanceManager;

    constructor(
        player: PlayerAPI,
        element: ListSelector<ListSelectorConfig>,
        uimanager: UIInstanceManager
    ) {
        this.player = player;
        this.listElement = element;
        this.uimanager = uimanager;

        this.bindSelectionEvent();
        this.bindPlayerEvents();
        this.refreshList();
    }

    private bindSelectionEvent(): void {
        this.listElement.onItemSelected.subscribe((_, value: string) => {
            const _player = getActivePlayer();
            const speeds = _player.getSpeeds();
            const speed = speeds.find((q) => q.id === value);
            if (speed) {
                _player.setSpeed(speed.speed);
                this.refreshList();
            }
        });
    }

    private bindPlayerEvents(): void {
        this.player.on(this.player.exports.PlayerEvent.VideoQualityChanged, this.clearList);
        this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.clearList);
        this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshList);
        this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshList);
    }

    private clearList = () => {
        this.listElement.clearItems();
    };

    private refreshList = () => {
        const isLive = this.player.isLive();
        if (isLive) {
            this.clearList();
            return;
        }

        const _player = getActivePlayer();
        const speeds = _player.getSpeeds();
        let currentSpeed = null;
        const speedToListItem = (speed: any): ListItem => {
            if (speed.selected) {
                currentSpeed = speed.id;
            }
            return { key: speed.id, label: speed.label };
        };

        this.listElement.synchronizeItems([...speeds.map(speedToListItem)]);
        this.listElement.selectItem(currentSpeed || 'auto');
    };
}
