import { PlayerAPI } from '../..';
import { Label, PlayerUtils, UIInstanceManager } from 'bitmovin-player-ui';
import { LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { getActivePlayer } from '../../utilities/player';

/**
 * A Component to show the AssetLogo or channel logo
 */
export class AssetLogo extends Label<LabelConfig> {
    constructor(config: LabelConfig = {}) {
        super(config);

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-assetLogo',
                hideInLivePlayback: false,
            } as LabelConfig,
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);
        const isLive = player.isLive();

        if (!isLive) {
            this.hide();
        }

        const liveStreamDetector = new PlayerUtils.LiveStreamDetector(player, uimanager);
        liveStreamDetector.onLiveChanged.subscribe(
            (sender, args: PlayerUtils.LiveStreamDetectorEventArgs) => {
                if (args.live) {
                    updateLogo();
                    this.show();
                } else {
                    this.hide();
                }
            }
        );

        const updateLogo = () => {
            const _player = getActivePlayer();
            this.getDomElement().css({
                'background-image': `url(${_player.assetLogo})`,
            });
        };

        player.on(player.exports.PlayerEvent.SourceLoaded, updateLogo);
        player.on(player.exports.PlayerEvent.SourceUnloaded, updateLogo);
        player.on(player.exports.PlayerEvent.Ready, updateLogo);
    }
}
