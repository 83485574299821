import { Container, NavigationGroup, UIInstanceManager } from 'bitmovin-player-ui';
import { PlayerAPI } from '../..';
import { Label, LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';

import { ContainerConfig } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { CONFIG } from 'src/config/config';

import { getActivePlayer } from '../../utilities/player';

/**
 * Overlays the player and displays Zombie kick error messages.
 */
export class ZombieKickOverlay extends Container<ContainerConfig> {
    private timeoutLabel: Label<LabelConfig>;
    private uimanager: UIInstanceManager | undefined;

    private mainTimer: ReturnType<typeof setTimeout> | undefined;
    private awayTimer: ReturnType<typeof setInterval> | undefined;

    private isAway = false;

    constructor(config: ContainerConfig = {}) {
        super(config);

        const titleLabel = new Label({
            text: 'Are you still watching?',
            cssClass: 'ui-titleMessage-label',
        });

        const supportLabel = new Label({
            text: 'Press any key on your remote to confirm.',
            cssClass: 'ui-supportMessage-label',
        });

        this.timeoutLabel = new Label({
            text: 'Stream will disconnect soon',
            cssClass: 'ui-timeoutMessage-label',
        });

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-zombieKick-overlay',
                components: [titleLabel, supportLabel, this.timeoutLabel],
                hidden: true,
            },
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);
        this.uimanager = uimanager;

        if (!CONFIG.AYSWActive) {
            return;
        }

        this.startMainTimer();

        window.addEventListener('keyup', this.keyListener);
    }

    keyListener = (e: any) => {
        if (this.isAway) {
            this.hideAway();
        }

        this.startMainTimer();
        return false;
    };

    clearTimers = () => {
        if (this.mainTimer) {
            clearTimeout(this.mainTimer);
        }

        if (this.awayTimer) {
            clearInterval(this.awayTimer);
        }
    };

    startMainTimer = () => {
        this.clearTimers();
        this.mainTimer = setTimeout(() => {
            const player = getActivePlayer();
            if (player.isPlaying()) {
                this.displayAway();
            } else {
                this.startMainTimer();
            }
        }, CONFIG.AYSWNotificationTimeout);
    };

    startAwayTimer = () => {
        let timeout = CONFIG.AYSWAutoExitTimeout / 1000;

        this.awayTimer = setInterval(() => {
            if (timeout > 0) {
                const text = `Stream will disconnect in <strong>${timeout} seconds</strong>`;
                this.timeoutLabel.setText(text);
                timeout--;
            } else {
                this.exitPlayBack();
            }
        }, 1000);
    };

    displayAway = () => {
        let timeout = CONFIG.AYSWAutoExitTimeout / 1000;
        const text = `Stream will disconnect in ${timeout--} seconds`;
        this.timeoutLabel.setText(text);

        this.isAway = true;
        this.show();
        this.uimanager!.getUI().getDomElement().addClass('ui-zombieKick');
        this.getDomElement().focusToFirstInput();
        this.startAwayTimer();
    };

    hideAway = () => {
        this.isAway = false;
        this.hide();
        this.uimanager!.getUI().getDomElement().removeClass('ui-zombieKick');
        this.startMainTimer();
    };

    exitPlayBack = () => {
        window.history.back();
    };

    release(): void {
        super.release();
        this.clearTimers();
        window.removeEventListener('keyup', this.keyListener);
    }

    navigationGroup() {
        return new NavigationGroup(this);
    }
}
