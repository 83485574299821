import { PlayerClass } from '../player';

export function reset(this: PlayerClass) {
    this.setError(null);
    this.resetSource();
    this.error = null;
}

export function resetSource(this: PlayerClass) {
    this.player = null;
    this.assetId = null;
    this.assetLogo = null;
    this.assetPoster = null;
    this.assetUrl = null;
    this.sourceConfig = null;
    console.log('RESET');
}
