import { Container, Label, NavigationGroup, UIInstanceManager } from 'bitmovin-player-ui';
import { PlayerAPI } from '../..';
import { ContainerConfig } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { getActivePlayer } from '../../utilities/player';
import { ChannelButton, ChannelButtonConfig } from './ChannelButton';
import { getLiveAssetEPGData } from 'src/apis/dashboard';
import { formatLiveAssetPlaybackData } from 'src/utilities/asset';
import { EPGEventDataType } from 'src/state/stores/dashboard/dashboard';
import { formatTime } from 'src/utilities/time';

export interface EPGConfig extends ContainerConfig {
    onPopup: () => void;
    onDismiss: () => void;
}

/**
 * Overlays the player and displays error messages.
 */
export class EPG extends Container<EPGConfig> {
    private channelButtons: ChannelButton<ChannelButtonConfig>[] = [];
    private channelButtonContainer: Container<ContainerConfig>;

    private epgDataSet: { [index: string]: EPGEventDataType[] | null | false } = {};
    private focusedChannelButtonId: string | null = null;

    private labelNowProgramTime: Label<LabelConfig>;
    private labelNowProgramTitle: Label<LabelConfig>;
    private labelNowProgramDescription: Label<LabelConfig>;
    private labelNowProgramProgress: Label<LabelConfig>;
    private labelNowNoProgram: Label<LabelConfig>;
    private labelNowProgramSpinner: Label<LabelConfig>;

    private labelNextProgramTime: Label<LabelConfig>;
    private labelNextProgramTitle: Label<LabelConfig>;
    private labelNextProgramDescription: Label<LabelConfig>;
    private labelNextNoProgram: Label<LabelConfig>;
    private labelNextProgramSpinner: Label<LabelConfig>;

    private onPopup: () => void;
    private onDismiss: () => void;

    constructor(config: EPGConfig) {
        super(config);

        this.channelButtons = [];
        this.channelButtonContainer = new Container({
            components: [],
            cssClasses: ['channel-container'],
        });

        this.labelNowProgramTime = new Label({
            text: '',
            cssClasses: ['now-program-time'],
        });
        this.labelNowProgramTitle = new Label({
            text: '',
            cssClasses: ['now-program-title'],
        });
        this.labelNowProgramDescription = new Label({
            text: '',
            cssClasses: ['now_program-description'],
        });
        this.labelNowProgramProgress = new Label({
            text: '',
            cssClasses: ['now-program-progress'],
        });

        this.labelNextProgramTime = new Label({
            text: '',
            cssClasses: ['next-program-time'],
        });
        this.labelNextProgramTitle = new Label({
            text: '',
            cssClasses: ['next-program-title'],
        });
        this.labelNextProgramDescription = new Label({
            text: '',
            cssClasses: ['next_program-description'],
        });

        this.labelNowNoProgram = new Label({
            text: 'No information available at this time',
            cssClasses: ['no-program-title'],
            hidden: true,
        });
        this.labelNextNoProgram = new Label({
            text: 'No information available at this time',
            cssClasses: ['no-program-title'],
            hidden: true,
        });
        this.labelNowProgramSpinner = new Label({
            text: '',
            cssClasses: ['spinner'],
            hidden: true,
        });
        this.labelNextProgramSpinner = new Label({
            text: '',
            cssClasses: ['spinner'],
            hidden: true,
        });

        const nowProgramContainer = new Container({
            components: [
                this.labelNowProgramTime,
                this.labelNowProgramTitle,
                this.labelNowProgramDescription,
                // this.nowProgramProgress,
                this.labelNowNoProgram,
                this.labelNowProgramSpinner,
            ],
            cssClasses: ['now-program-container'],
        });

        const nextProgramContainer = new Container({
            components: [
                this.labelNextProgramTime,
                this.labelNextProgramTitle,
                this.labelNextProgramDescription,
                this.labelNextNoProgram,
                this.labelNextProgramSpinner,
            ],
            cssClasses: ['next-program-container'],
        });

        const programContainer = new Container({
            components: [nowProgramContainer, nextProgramContainer],
            cssClasses: ['program-container'],
        });

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-epg-container',
                components: [this.channelButtonContainer, programContainer],
                hidden: true,
                onPopup: () => {},
                onDismiss: () => {},
            },
            this.config
        );

        this.onPopup = config.onPopup;
        this.onDismiss = config.onDismiss;

        this.refreshChannelList();
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        uimanager.onControlsHide.subscribe(() => {
            this.hide();
        });

        this.onShow.subscribe(() => {
            this.getDomElement().focusToFirstInput();
            this.popup();
        });

        this.onHide.subscribe(() => {
            this.dismiss();
        });
    }

    onChannelButtonFocus = (assetId: string) => {
        this.focusedChannelButtonId = assetId;
        this.clearProgramInfo();
        this.fetchEPGData(assetId);
        this.setProgramInfo();
    };

    fetchEPGData = (assetId: string) => {
        if (this.epgDataSet[assetId] !== undefined) {
            return;
        }

        this.epgDataSet[assetId] = null;
        const resp = getLiveAssetEPGData(assetId)();
        resp.then(({ data }) => {
            const epgData = formatLiveAssetPlaybackData(data);
            if (epgData && epgData.length) {
                this.epgDataSet[assetId] = epgData;
            } else {
                this.epgDataSet[assetId] = false;
            }
            this.setProgramInfo();
        });
    };

    refreshChannelList = () => {
        const _player = getActivePlayer();
        const channelsButtons =
            _player.liveAssets?.map((l) => {
                const cb = new ChannelButton({
                    cssClasses: ['epg-channel-button'],
                    assetId: l.id,
                    logo: l.logo,
                    onFocus: this.onChannelButtonFocus,
                });

                this.channelButtonContainer.addComponent(cb);
                return cb;
            }) || [];
        this.channelButtons = channelsButtons;
    };

    clearProgramInfo = () => {
        this.labelNowProgramTime.setText('');
        this.labelNowProgramTitle.setText('');
        this.labelNowProgramDescription.setText('');

        this.labelNextProgramTime.setText('');
        this.labelNextProgramTitle.setText('');
        this.labelNextProgramDescription.setText('');

        this.labelNowNoProgram.hide();
        this.labelNextNoProgram.hide();

        this.labelNowProgramSpinner.show();
        this.labelNextProgramSpinner.show();
    };

    setProgramInfo = () => {
        const epgData = this.focusedChannelButtonId
            ? this.epgDataSet[this.focusedChannelButtonId]
            : [];
        const now = new Date().getTime();

        if (epgData === null) {
            this.labelNowProgramSpinner.show();
            this.labelNextProgramSpinner.show();
            return;
        }

        this.labelNowProgramSpinner.hide();
        this.labelNextProgramSpinner.hide();

        if (epgData === false) {
            this.labelNowNoProgram.show();
            this.labelNextNoProgram.show();
            return;
        }

        const onNowIndex = epgData.findIndex((e: any) => e.epgStart <= now && e.epgStop >= now);
        if (onNowIndex >= 0) {
            const onNowProgram = epgData[onNowIndex];
            const onNextProgram = epgData[onNowIndex + 1] || null;

            if (onNowProgram) {
                const { title, epgStart, epgStop, shortEventDescription } = onNowProgram;
                let time =
                    epgStart && epgStop ? `${formatTime(epgStart)} - ${formatTime(epgStop)}` : '';

                this.labelNowProgramTime.setText(time);
                this.labelNowProgramTitle.setText(title);
                this.labelNowProgramDescription.setText(shortEventDescription);
            } else {
                this.labelNowNoProgram.show();
            }

            if (onNextProgram) {
                const { title, epgStart, epgStop, shortEventDescription } = onNextProgram;
                let time =
                    epgStart && epgStop ? `${formatTime(epgStart)} - ${formatTime(epgStop)}` : '';

                this.labelNextProgramTime.setText(time);
                this.labelNextProgramTitle.setText(title);
                this.labelNextProgramDescription.setText(shortEventDescription);
            } else {
                this.labelNextNoProgram.show();
            }
        }
    };

    popup(): void {
        this.channelButtonContainer.getDomElement().focusToFirstInput();
        this.onPopup();
    }

    dismiss(): void {
        this.onDismiss();
    }

    release(): void {
        super.release();
    }

    navigationGroup() {
        return new NavigationGroup(this, ...this.channelButtons);
    }
}
