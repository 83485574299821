import { Button, Container, NavigationGroup, UIInstanceManager } from 'bitmovin-player-ui';

import { ErrorCode, PlayerAPI, PlayerEventBase } from '../..';

import { ErrorMessageOverlayConfig } from 'bitmovin-player-ui/dist/js/framework/components/errormessageoverlay';
import { Label, LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { getActivePlayer } from '../../utilities/player';
import { ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button';

export interface ErrorEvent {
    code: ErrorCode;
    name: string;
    message?: string;
    data?: {
        [key: string]: any;
    };
    sourceIdentifier?: string;
    troubleShootLink: string;
}

/**
 * Overlays the player and displays error messages.
 */
export class ErrorMessageOverlay extends Container<ErrorMessageOverlayConfig> {
    private errorLabel: Label<LabelConfig>;
    private retryButton: Button<ButtonConfig>;

    constructor(config: ErrorMessageOverlayConfig = {}) {
        super(config);

        const titleLabel = new Label({
            text: 'Oops! Something went wrong...',
            cssClass: 'ui-titleMessage-label',
        });
        this.errorLabel = new Label({ cssClass: 'ui-errorMessage-label' });
        const supportLabel = new Label({
            text: 'Please try again or contact our customer support team via the Optus Sport website or mobile app.',
            cssClass: 'ui-supportMessage-label',
        });

        this.retryButton = new Button({
            cssClasses: ['ui-retry-button'],
            text: 'Retry',
        });

        const buttonsContainer = new Container({
            cssClass: 'ui-errorMessage-buttons',
            components: [this.retryButton],
        });

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-errorMessage-overlay',
                components: [titleLabel, this.errorLabel, supportLabel, buttonsContainer],
                hidden: true,
            },
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        this.retryButton.onClick.subscribe(() => {
            const _player = getActivePlayer();
            this.hide();
            _player.retry();
        });

        const handleErrorMessage = (event: ErrorEvent) => {
            const customizedMessage = `${event.code}`;
            this.display(customizedMessage);
        };

        // @ts-ignore
        player.on(player.exports.PlayerEvent.Error, (event: ErrorEvent) => {
            handleErrorMessage(event);
        });

        player.on(player.exports.PlayerEvent.SourceLoaded, (event: PlayerEventBase) => {
            if (this.isShown()) {
                this.hide();
            }
        });
    }

    display(errorMessage: string): void {
        const _player = getActivePlayer();
        this.getDomElement().css({
            'background-image': `url(${_player.assetPoster})`,
        });
        this.errorLabel.setText(errorMessage);
        this.show();
        this.getDomElement().focusToFirstInput();
    }

    release(): void {
        super.release();
    }

    navigationGroup() {
        return new NavigationGroup(this, this.retryButton);
    }
}
