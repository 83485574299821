import { PlayerAPI } from '../..';
import { UIInstanceManager } from 'bitmovin-player-ui';
import {
    ListItem,
    ListSelector,
    ListSelectorConfig,
} from 'bitmovin-player-ui/dist/js/framework/components/listselector';
import { getActivePlayer } from '../../utilities/player';

/**
 * Helper class to handle all video quality related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
export class VideoQualitySwitchHandler {
    private player: PlayerAPI;
    private listElement: ListSelector<ListSelectorConfig>;
    private uiManager: UIInstanceManager;
    constructor(
        player: PlayerAPI,
        element: ListSelector<ListSelectorConfig>,
        uiManager: UIInstanceManager
    ) {
        this.player = player;
        this.listElement = element;
        this.uiManager = uiManager;

        this.bindSelectionEvent();
        this.bindPlayerEvents();
        this.refreshQualities();
    }

    private bindSelectionEvent(): void {
        this.listElement.onItemSelected.subscribe((_, value: string) => {
            const _player = getActivePlayer();
            const qualities = _player.getQualities();
            const quality = qualities.find((q) => q.id === value);
            if (quality) {
                _player.setQuality(quality?.label);
                this.refreshQualities();
            }
        });
    }

    private bindPlayerEvents(): void {
        this.player.on(this.player.exports.PlayerEvent.VideoQualityChanged, this.clearQualities);
        this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.clearQualities);
        this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshQualities);
        this.uiManager.getConfig().events.onUpdated.subscribe(this.refreshQualities);
    }

    private clearQualities = () => {
        this.listElement.clearItems();
    };

    private refreshQualities = () => {
        const _player = getActivePlayer();
        if (!_player) {
            return;
        }

        const qualities = _player.getQualities();
        let currentQuality = null;
        const qualityToListItem = (quality: any): ListItem => {
            if (quality.selected) {
                currentQuality = quality.id;
            }
            return { key: quality.id, label: quality.label };
        };

        this.listElement.synchronizeItems([...qualities.map(qualityToListItem)]);
        this.listElement.selectItem(currentQuality || 'auto');
    };
}
