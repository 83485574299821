import { useEffect, useRef } from 'react';
import { isKeyBackButton } from 'src/utilities/keys';

type Callback = (event: KeyboardEvent) => boolean;

type AddCallbackProp = {
    randomID: string;
    callBack: Callback;
    rank: number;
};

let callBacks: AddCallbackProp[] = [];

const delegateEvent = (event: KeyboardEvent, level: number = 0) => {
    const cb = callBacks[level] || null;
    const fn = cb && cb.callBack;

    if (fn) {
        const stopPropagation = () => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        };
        if (fn(event)) {
            stopPropagation();
        } else {
            delegateEvent(event, level + 1);
        }
    }
};

const handleKeyDown = (event: KeyboardEvent) => {
    if (!isKeyBackButton(event)) {
        delegateEvent(event);
    }
};

document.addEventListener('keydown', handleKeyDown);

export const removeKeyPressHandler = () => {
    document.removeEventListener('keydown', handleKeyDown);
};

const addCallBack = (prop: AddCallbackProp) => {
    removeCallBack(prop.randomID);
    callBacks.push(prop);
    callBacks = callBacks.sort((a, b) => a.rank - b.rank);
};

const removeCallBack = (randomID: string) => {
    callBacks = callBacks.filter((c) => c.randomID !== randomID);
};

const useKeyPress = (callBack: Callback, rank = 50, id = null) => {
    const randomID = useRef(id || Math.random().toString());
    useEffect(() => {
        addCallBack({
            randomID: randomID.current,
            callBack,
            rank,
        });
    }, [callBack, rank]);
    useEffect(() => () => removeCallBack(randomID.current), []);
};

export default useKeyPress;

export class KeyPressController {
    private randomId: string;
    constructor() {
        this.randomId = Math.random().toString();
    }
    addNewCallbackToStack(callBack: Callback, rank: number) {
        addCallBack({ randomID: this.randomId, callBack, rank });
    }
    removeCallBack() {
        removeCallBack(this.randomId);
    }
}
