import { useEffect } from 'react';
import { getBitmovinSourceConfig } from '../utilities/player';
import { Player } from '../player';
import { errorLog, log } from 'src/utilities/console';
import {
    PlaybackType,
    WatchModeLiveType,
    WatchModeType,
} from 'src/state/stores/dashboard/dashboard';
import { CombSegmentDataType } from '../player.d';

interface UseBitmovinProps {
    assetId: string | undefined;
    playback: PlaybackType | undefined;
    startTime: number | undefined;
    poster: string | undefined;
    segmentData: CombSegmentDataType | undefined;
    watchMode?: WatchModeLiveType | WatchModeType | null;
    assetLogo?: string;
    retryPlayback?: () => void;
}

const useBitmovin = (props: UseBitmovinProps) => {
    const {
        assetId,
        playback,
        startTime,
        poster,
        segmentData,
        watchMode,
        assetLogo,
        retryPlayback,
    } = props;

    useEffect(() => {
        if (!(assetId && playback && segmentData)) {
            return;
        }
        try {
            log({ playback, segmentData });
            const { sourceConfig, isSSAIEnabled } = getBitmovinSourceConfig(
                playback,
                startTime,
                poster,
                watchMode,
                segmentData.assetData.title
            );
            Player.load(
                assetId,
                sourceConfig,
                isSSAIEnabled,
                segmentData,
                watchMode,
                assetLogo
            ).catch(() => {});
        } catch (error) {
            errorLog(error);
            Player.setError('Asset not found');
        }
    }, [assetId, assetLogo, playback, startTime, poster, segmentData, watchMode]);

    useEffect(() => {
        if (retryPlayback) {
            return Player.onRetry(retryPlayback);
        }
    }, [retryPlayback]);

    useEffect(
        () => () => {
            // const ps = ['DESTROYED', 'DESTROYING', 'UNLOADED', 'UNLOADING'];
            // if (
            //     (!Player.isLive() && Player.playerState === 'FINISHED') ||
            //     (Player.isLive() && ps.includes(Player.playerState))
            // ) {
            //     Player.unload(false);
            // } else {
            Player.unload();
            // }
        },
        []
    );
    return { Player };
};

export default useBitmovin;
