import useLivePlayback from 'src/controllers/livePlayback.controller';
import styles from './playback.module.scss';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';
import useUser from 'src/controllers/auth/user.controller';
import usePlaybackError from 'src/controllers/playbackError.controller';
import PlayerUI from 'src/components/Player/PlayerUI';
import { useCallback } from 'react';
import { FocusNode } from 'lal-lrud';
import { Segment } from 'src/lib/bitmovin';
import SubscriptionPending from 'src/components/ui/SubscriptionPending';
import { CONFIG } from 'src/config/config';
import Spinner from 'src/components/Common/Spinner';

const LivePlayback = () => {
    const navigate = useNavigate();
    const {
        isKicked,
        isKickedOnPlayback,
        playbackError,
        isGeoBlocked,
        isUserCanPlay,
        poster,
        retryPlayback,
    } = useLivePlayback();
    const { user } = useUser();
    const location = useLocation();

    const { retryOnError } = usePlaybackError(retryPlayback);

    const navigateHome = useCallback(() => {
        navigate(RouteTags.root, { replace: true });
    }, [navigate]);

    const navigateToLogin = useCallback(() => {
        navigate(RouteTags.login, {
            state: { redirectOnSuccess: { pathname: location.pathname } },
        });
    }, [navigate, location.pathname]);

    const navigateToDeviceManagement = useCallback(() => {
        Segment.trackPlaybackErrorInteraction({
            interaction: 'SHOW_DEVICE_MANAGEMENT',
        });
        navigate(RouteTags.deviceManagement, { replace: true });
    }, [navigate]);

    const bgStyle = { backgroundImage: '' };

    if (poster) {
        bgStyle.backgroundImage = `url(${poster})`;
    }

    if (!user) {
        return (
            <FullscreenPopup
                id={'live.loginPending'}
                onClick={navigateToLogin}
                onExit={navigateHome}
            ></FullscreenPopup>
        );
    }

    if (isGeoBlocked) {
        return (
            <FullscreenPopup
                id={'player.geoLocationError'}
                onClick={navigateHome}
                onExit={navigateHome}
            ></FullscreenPopup>
        );
    } else if (isKickedOnPlayback) {
        return (
            <FullscreenPopup
                id={'deviceManagement.deviceExceeded'}
                onClick={navigateToDeviceManagement}
                onClickSecondary={navigateHome}
                onExit={navigateHome}
                isTrap={false}
            ></FullscreenPopup>
        );
    }

    if (playbackError) {
        return (
            <FullscreenPopup
                id={'player.genericError'}
                onClick={retryOnError}
                onExit={navigateHome}
                onClickSecondary={navigateHome}
                primaryButtonTitle="Retry"
            />
        );
    }

    if (isUserCanPlay === false) {
        return <SubscriptionPending hideNotification={navigateHome} />;
    }

    return (
        <FocusNode className={styles.playback} orientation="vertical">
            {poster ? <div className={styles.poster} style={bgStyle} /> : null}
            {poster !== false ? <Spinner fullScreen /> : null}

            {CONFIG.bitmovinTVUIActive ? null : (
                <>
                    <PlayerUI
                        isLive={true}
                        isShowMiniEPG={true}
                        isPlayerError={!!playbackError}
                        isKicked={isKicked || isKickedOnPlayback}
                        isMiniUI={false}
                    />

                    {playbackError ? (
                        <FullscreenPopup
                            id={'live.error'}
                            onClick={retryOnError}
                            onClickSecondary={navigateHome}
                            onExit={navigateHome}
                            primaryButtonTitle="Retry"
                            titleMessage={playbackError}
                            isTrap={false}
                        />
                    ) : isKicked ? (
                        <FullscreenPopup
                            id={'player.streamKicked'}
                            onClick={retryOnError}
                            onClickSecondary={navigateHome}
                            onExit={navigateHome}
                            isTrap={false}
                        ></FullscreenPopup>
                    ) : null}
                </>
            )}
        </FocusNode>
    );
};

export default LivePlayback;
