import { Button, ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button';
import { PlayerAPI } from '../..';
import { PlayerUtils, UIInstanceManager } from 'bitmovin-player-ui';

/**
 * A button to play/replay a video.
 */
export class LiveButton extends Button<ButtonConfig> {
    constructor(config: ButtonConfig = {}) {
        super(config);

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-livebutton',
                text: 'Live',
                ariaLabel: 'live',
            },
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);
        const isLive = player.isLive();
        let notLiveCssClass = this.prefixCss('ui-not-live');

        if (!isLive) {
            this.hide();
        }

        const liveStreamDetector = new PlayerUtils.LiveStreamDetector(player, uimanager);
        liveStreamDetector.onLiveChanged.subscribe(
            (sender, args: PlayerUtils.LiveStreamDetectorEventArgs) => {
                if (args.live) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        );

        this.onClick.subscribe(() => {
            this.setText('Live');
            this.getDomElement().removeClass(notLiveCssClass);
            player.timeShift(0);
        });

        const updateLiveState = () => {
            const isAt0 = Math.round(player.getTimeShift()) === 0;
            if (isAt0) {
                this.setText('Live');
                this.getDomElement().removeClass(notLiveCssClass);
            } else {
                this.setText('Back to Live');
                this.getDomElement().addClass(notLiveCssClass);
            }
        };

        player.on(player.exports.PlayerEvent.TimeChanged, updateLiveState);
        player.on(player.exports.PlayerEvent.Ready, updateLiveState);
        player.on(player.exports.PlayerEvent.Seeked, updateLiveState);
        player.on(player.exports.PlayerEvent.TimeShift, updateLiveState);
        player.on(player.exports.PlayerEvent.TimeShifted, updateLiveState);
        player.on(player.exports.PlayerEvent.Playing, updateLiveState);
        player.on(player.exports.PlayerEvent.Paused, updateLiveState);
        player.on(player.exports.PlayerEvent.StallStarted, updateLiveState);
        player.on(player.exports.PlayerEvent.StallEnded, updateLiveState);
    }
}
