import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStateValue } from 'src/state';
import {
    getAssetMetaData,
    getLiveAssetPlaybackData,
} from 'src/state/stores/dashboard/dashboard.actions';
import { formatAssetPlaybackData, getLivePoster, getLiveSegmentData } from 'src/utilities/asset';
import useUser from './auth/user.controller';
import usePlaybackWatchmode from './playbackWatchmode.controller';
import { checkIfGeoBlocked, checkIfKickedOnPlaybackReq } from 'src/utilities/errorValidation';
import { Player } from 'src/lib/bitmovin/player';
import usePlaybackSession from './playbackSession.controller';
import useLiveAsset from './liveAsset.controller';
import useBitmovin from 'src/lib/bitmovin/controllers/bitmovin.controller';
import usePlaybackState from './playerPlaybackState.controller';
import { useKickedOnPlayback } from './kickedOnPlayback.controller';
import { useSegmentData } from './analytics/segmentData.controller';
import { BitmovinOptusPlayer } from 'src/lib/bitmovin';
import { type WatchMode } from 'src/lib/bitmovin';

import useMiniEPG from './miniEPG.controller';

// @NOTE: This particular function is used only for Linear streams.
// i.e. Streams using the /live route.
const useLivePlayback = () => {
    const [playbackData, setPlaybackData] = useState<any>(undefined);
    const [isGeoBlocked, setIfGeoBlocked] = useState<boolean>(false);
    const [playbackError, setPlaybackError] = useState<boolean>(false);
    const [isKickedOnPlayback, setIsKickedOnPlayback] = useState<boolean>(false);

    const { dispatch } = useStateValue();
    const { asset, isUserCanPlay } = useLiveAsset();
    const { isKicked, setIsKicked } = usePlaybackSession();
    const { playing } = usePlaybackState();
    const { auth, userData } = useUser();
    const { userId } = auth || {};
    const { settings: userSettings } = userData || {};

    useMiniEPG({ fetch: true });

    const { watchMode } = usePlaybackWatchmode();

    const getPlaybackData = useCallback(() => {
        if (!asset || !userId || !isUserCanPlay || playbackData !== undefined) {
            return;
        }

        setPlaybackData(null);
        // Used for Ad Holiday
        BitmovinOptusPlayer.setAssetData(asset as any);
        BitmovinOptusPlayer.setWatchMode(watchMode as WatchMode);
        Player.initTracking();

        dispatch(getLiveAssetPlaybackData(asset, userId, userSettings?.prohibitTargeting))
            .then((d) => {
                setPlaybackData(d.playback);
            })
            .catch((e) => {
                if (checkIfGeoBlocked(e)) {
                    setIfGeoBlocked(true);
                } else if (checkIfKickedOnPlaybackReq(e)) {
                    setIsKickedOnPlayback(true);
                } else {
                    setPlaybackError(true);
                }
            });
        dispatch(getAssetMetaData(asset.id));
    }, [
        asset,
        dispatch,
        isUserCanPlay,
        playbackData,
        userId,
        watchMode,
        userSettings?.prohibitTargeting,
    ]);

    useSegmentData(asset);

    useEffect(() => {
        getPlaybackData();
    }, [getPlaybackData]);

    useEffect(() => {
        if (asset && playbackData && asset.id !== playbackData['@assetId']) {
            setIfGeoBlocked(false);
            setPlaybackError(false);
            setPlaybackData(undefined);
        }
    }, [asset, playbackData]);

    const [lastAssetId, setLastAssetId] = useState<string>();

    useEffect(() => {
        if (asset) {
            if (lastAssetId && lastAssetId !== asset.id) {
                Player.generateSessionId();
            }
            setLastAssetId(asset.id);
        }
    }, [asset, lastAssetId]);

    const retryPlayback = useCallback(() => {
        const retry = () => {
            setIsKicked(false);
            setIfGeoBlocked(false);
            setPlaybackError(false);
            setPlaybackData(undefined);
        };
        Player.unload().then(retry).catch(retry);
    }, [setIsKicked]);

    const [playback, poster, segmentData] = useMemo(
        () =>
            asset && playbackData && userData
                ? [
                      formatAssetPlaybackData(playbackData, 'live'),
                      getLivePoster(asset),
                      getLiveSegmentData(userData, asset, playbackData),
                  ]
                : [undefined, undefined, undefined],
        [asset, playbackData, userData]
    );

    useBitmovin({
        assetId: asset?.id,
        playback: asset?.id === playbackData?.['@assetId'] ? playback : undefined,
        startTime: 0,
        poster,
        segmentData,
        watchMode,
        assetLogo: asset?.logo_controls,
    });

    useKickedOnPlayback(isKickedOnPlayback);
    return {
        isGeoBlocked,
        isKicked,
        isKickedOnPlayback,
        playbackError,
        poster: !playing && poster,
        retryPlayback,
        isUserCanPlay,
    };
};

export default useLivePlayback;
