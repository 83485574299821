import { PlayerAPI } from '../..';
import { UIInstanceManager } from 'bitmovin-player-ui';
import {
    Component,
    ComponentConfig,
} from 'bitmovin-player-ui/dist/js/framework/components/component';
import { DOM } from 'bitmovin-player-ui/dist/js/framework/dom';
import {
    Event,
    EventDispatcher,
    NoArgs,
} from 'bitmovin-player-ui/dist/js/framework/eventdispatcher';
import { getRouteTags } from 'src/routes/routeTags';
import { getActivePlayer } from '../../utilities/player';

export interface ChannelButtonConfig extends ComponentConfig {
    assetId: string;
    logo: string;
    onFocus: (assetId: string) => void;
}

/**
 * A simple clickable button.
 */
export class ChannelButton<Config extends ChannelButtonConfig> extends Component<Config> {
    private buttonEvents = {
        onClick: new EventDispatcher<ChannelButton<Config>, NoArgs>(),
    };
    private assetId: string;
    private player: PlayerAPI | undefined;
    constructor(config: Config) {
        super(config);

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-button',
                role: 'button',
                tabIndex: 0,
            } as Config,
            this.config
        );
        this.assetId = config.assetId;
    }
    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        window.addEventListener('popstate', (e) => {
            const currentChannleClass = 'bmpui-epg-current-channel-button';
            if (window.location.hash.endsWith(this.assetId)) {
                this.getDomElement().addClass(currentChannleClass);
            } else {
                this.getDomElement().removeClass(currentChannleClass);
            }
        });
    }

    protected toDomElement(): DOM {
        const buttonElementAttributes: { [name: string]: string } = {
            id: this.config.id!,
            'aria-label': this.assetId,
            class: this.getCssClasses(),
            type: 'button',
            'aria-pressed': 'false',
            tabindex: this.config.tabIndex!.toString(),
            style: `background-image: url(${this.config.logo})`,
        };

        if (this.config.role != null) {
            buttonElementAttributes['role'] = this.config.role;
        }

        const buttonElement = new DOM('button', buttonElementAttributes, this);

        if (window.location.hash.endsWith(this.assetId)) {
            buttonElement.addClass('bmpui-epg-current-channel-button');
        }

        buttonElement.on('click', () => {
            this.onClickEvent();
        });

        buttonElement.on('focus', () => {
            try {
                this.config.onFocus(this.assetId);
                // @ts-ignore
                buttonElement.elements[0].scrollIntoView({ inline: 'center' });
            } catch (error) {}
        });

        return buttonElement;
    }

    protected navigate(href: string) {
        window.location.replace(`#${href}`);
    }

    protected onClickEvent() {
        this.buttonEvents.onClick.dispatch(this);
        const _player = getActivePlayer();
        _player.pause();
        _player.unload();
        this.navigate(getRouteTags('livePlayback', 'assetId', this.assetId));
    }

    /**
     * Gets the event that is fired when the button is clicked.
     * @returns {Event<Button<Config>, NoArgs>}
     */
    get onClick(): Event<ChannelButton<Config>, NoArgs> {
        return this.buttonEvents.onClick.getEvent();
    }
}
