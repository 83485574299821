import { UIManager, UIVariant } from 'bitmovin-player-ui';
import { PlayerClass } from '../player';
import { getDefaultUI } from '../ui/defaultUI';
import { getAdUI } from '../ui/adUI';

let released = true;
export function initUI(this: PlayerClass) {
    // this.releaseUI();
    if (released) {
        const uiList: UIVariant[] = [getDefaultUI(), getAdUI()];

        this.uiManager = new UIManager(this.player, uiList, {
            disableAutoHideWhenHovered: true,
            playbackSpeedSelectionEnabled: true,
            container: '#bmui-controls',
        });
        released = false;
    }
}

export function releaseUI(this: PlayerClass) {
    this.uiManager?.release();
    this.uiManager = null;
    released = true;
}
