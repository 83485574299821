import { Button, Container, NavigationGroup, UIInstanceManager } from 'bitmovin-player-ui';

import { ErrorCode, PlayerAPI, PlayerEventBase } from '../..';

import { Label, LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';
import { getActivePlayer } from '../../utilities/player';
import { ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button';
import { PLAYER_EVENT_STREAM_KICKED } from '../../utilities/events';
import { ContainerConfig } from 'bitmovin-player-ui/dist/js/framework/components/container';
import { NotificationMessages } from 'src/components/ui/FullscreenPopup/notifications';

export interface ErrorEvent {
    code: ErrorCode;
    name: string;
    message?: string;
    data?: {
        [key: string]: any;
    };
    sourceIdentifier?: string;
    troubleShootLink: string;
}

/**
 * Overlays the player and displays stream kick error messages.
 */
export class StreamKickOverlay extends Container<ContainerConfig> {
    private primaryButton: Button<ButtonConfig>;
    private backButton: Button<ButtonConfig>;
    private titleLabel: Label<LabelConfig>;
    private supportLabel: Label<LabelConfig>;
    constructor(config: ContainerConfig = {}) {
        super(config);

        const { title, message, primaryButton } = NotificationMessages['player.streamKicked'];

        this.titleLabel = new Label({
            text: title,
            cssClass: 'ui-titleMessage-label',
        });

        this.supportLabel = new Label({
            text: message,
            cssClass: 'ui-supportMessage-label',
        });

        this.primaryButton = new Button({
            cssClasses: ['ui-retry-button'],
            text: primaryButton,
        });

        this.backButton = new Button({
            cssClasses: ['ui-retry-button'],
            text: 'Back',
        });

        const buttonsContainer = new Container({
            cssClass: 'ui-errorMessage-buttons',
            components: [this.primaryButton, this.backButton],
        });

        this.config = this.mergeConfig(
            config,
            {
                cssClass: 'ui-errorMessage-overlay',
                components: [this.titleLabel, this.supportLabel, buttonsContainer],
                hidden: true,
            },
            this.config
        );

        document.addEventListener(PLAYER_EVENT_STREAM_KICKED, this.onStreamKick);
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        this.primaryButton.onClick.subscribe(() => {
            const _player = getActivePlayer();
            this.hide();
            _player.retry();
        });

        player.on(player.exports.PlayerEvent.SourceLoaded, (event: PlayerEventBase) => {
            if (this.isShown()) {
                this.hide();
            }
        });
    }

    onStreamKick = () => {
        const _player = getActivePlayer();
        this.getDomElement().css({
            'background-image': `url(${_player.assetPoster})`,
        });

        this.show();
        this.getDomElement().focusToFirstInput();
    };

    release(): void {
        super.release();
        document.removeEventListener(PLAYER_EVENT_STREAM_KICKED, this.onStreamKick);
    }

    navigationGroup() {
        return new NavigationGroup(this, this.primaryButton, this.backButton);
    }
}

// TODO: HANDLE check when player throws any errors in parallel.
