import { Button, ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button';
import { PlayerAPI } from '../..';
import { PlayerUtils, UIInstanceManager } from 'bitmovin-player-ui';

export class EPGButton extends Button<ButtonConfig> {
    constructor(config: ButtonConfig = {}) {
        super(config);

        this.config = this.mergeConfig(
            config,
            {
                cssClasses: ['ui-epg-trigger-button'],
                // text: 'To open channel guide please press down',
                text: 'Open channel guide',
                ariaLabel: 'epg button',
            },
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);
        const isLive = player.isLive();
        if (!isLive) {
            this.hide();
        }

        const liveStreamDetector = new PlayerUtils.LiveStreamDetector(player, uimanager);
        liveStreamDetector.onLiveChanged.subscribe(
            (sender, args: PlayerUtils.LiveStreamDetectorEventArgs) => {
                if (args.live) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        );

        this.onClick.subscribe(() => {});
    }
}
