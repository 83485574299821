import { PlayerAPI } from '../..';
import { ListBox, UIInstanceManager } from 'bitmovin-player-ui';
import { VideoQualitySwitchHandler } from '../handlers/videoQualitySwitchHandler';

/**
 * A element that is similar to a select box where the user can select a video quality
 */
export class VideoQualityListBox extends ListBox {
    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);
        new VideoQualitySwitchHandler(player, this, uimanager);
    }
}
