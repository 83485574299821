import { ReactComponent as ff } from '../../assets/icons/app/fastForward.svg';
import { ReactComponent as fitness } from '../../assets/icons/app/iconFitnessRunningSolid.svg';
import { ReactComponent as football } from '../../assets/icons/app/iconMatchGoal.svg';
import { ReactComponent as likeUnfilled } from '../../assets/icons/app/iconLikeUnfilled.svg';
import { ReactComponent as liveTv } from '../../assets/icons/app/iconLiveTv.svg';
import { ReactComponent as pause } from '../../assets/icons/app/pause.svg';
import { ReactComponent as play } from '../../assets/icons/app/iconPlayerPlay.svg';
import { ReactComponent as rw } from '../../assets/icons/app/rewind.svg';
import { ReactComponent as search } from '../../assets/icons/app/iconSearch.svg';
import { ReactComponent as settings } from '../../assets/icons/app/iconSettings.svg';
import { ReactComponent as settingsWhite } from '../../assets/icons/app/iconSettingsWhite.svg';
import { ReactComponent as spinner } from '../../assets/icons/app/spinner.svg';
import { ReactComponent as tickOutLine } from '../../assets/icons/app/tickOutLine.svg';
import { ReactComponent as spoilerOn } from '../../assets/icons/app/iconSpoilersOn.svg';
import { ReactComponent as spoilerOff } from '../../assets/icons/app/iconSpoilersOff.svg';
import { ReactComponent as replayLength3 } from '../../assets/icons/app/iconPlayerReplayLength3.svg';
import { ReactComponent as replayLength5 } from '../../assets/icons/app/iconPlayerReplayLength5.svg';
import { ReactComponent as replayLength9 } from '../../assets/icons/app/iconPlayerReplayLength9.svg';
import { ReactComponent as replayLength10 } from '../../assets/icons/app/iconPlayerReplayLength10.svg';
import { ReactComponent as replayLength15 } from '../../assets/icons/app/iconPlayerReplayLength15.svg';
import { ReactComponent as replayLength25 } from '../../assets/icons/app/iconPlayerReplayLength25.svg';
import { ReactComponent as replayLength30 } from '../../assets/icons/app/iconPlayerReplayLength30.svg';
import { ReactComponent as replayLength45 } from '../../assets/icons/app/iconPlayerReplayLength45.svg';
import { ReactComponent as replayLength90 } from '../../assets/icons/app/iconPlayerReplayLength90.svg';
import { ReactComponent as tickSoloGreen } from '../../assets/icons/app/iconStatusTickSolo.svg';
import { ReactComponent as tickOutlineGreen } from '../../assets/icons/app/iconStatusTickOutline.svg';
import { ReactComponent as notificationError } from '../../assets/icons/app/iconNotificationError.svg';
import styles from './icon.module.scss';

export type IconType =
    | 'tick'
    | 'ff'
    | 'rw'
    | 'pause'
    | 'play'
    | 'spinner'
    | 'football'
    | 'fitness'
    | 'likeUnfilled'
    | 'liveTv'
    | 'settings'
    | 'settingsWhite'
    | 'search'
    | 'tickSoloGreen'
    | 'tickOutlineGreen'
    | 'spoilerOn'
    | 'spoilerOff'
    | 'replayLength3'
    | 'replayLength5'
    | 'replayLength9'
    | 'replayLength10'
    | 'replayLength15'
    | 'replayLength25'
    | 'replayLength30'
    | 'replayLength45'
    | 'replayLength90'
    | 'notificationError';

type IconProps = {
    icon: IconType;
    spin?: boolean;
    className?: string;
};

const iconMapping = {
    ff: ff,
    fitness: fitness,
    football: football,
    likeUnfilled: likeUnfilled,
    liveTv: liveTv,
    pause: pause,
    play: play,
    rw: rw,
    search: search,
    settings: settings,
    settingsWhite: settingsWhite,
    spinner: spinner,
    spoilerOff: spoilerOff,
    spoilerOn: spoilerOn,
    tick: tickOutLine,
    tickSoloGreen: tickSoloGreen,
    tickOutlineGreen: tickOutlineGreen,
    replayLength3: replayLength3,
    replayLength5: replayLength5,
    replayLength9: replayLength9,
    replayLength10: replayLength10,
    replayLength15: replayLength15,
    replayLength25: replayLength25,
    replayLength30: replayLength30,
    replayLength45: replayLength45,
    replayLength90: replayLength90,
    notificationError: notificationError,
};

function Icon(props: IconProps) {
    const { icon, spin = false, className } = props;
    const iconClasses = [styles.icon, className];
    if (spin) {
        iconClasses.push(styles.spin);
        iconClasses.push('forceAnim');
    }
    const IconImg = iconMapping[icon];
    return <IconImg className={iconClasses.join(' ')} />;
}

export default Icon;
