import { PlayerAPI } from '../..';
import { PlaybackTimeLabel, UIInstanceManager } from 'bitmovin-player-ui';
import { LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label';

import {
    PlaybackTimeLabelConfig,
    PlaybackTimeLabelMode,
} from 'bitmovin-player-ui/dist/js/framework/components/playbacktimelabel';
import { CONFIG } from 'src/config/config';

/**
 * A Component to show the ad remaining time during ad
 */
export class AdRemainingTime extends PlaybackTimeLabel {
    constructor(config: PlaybackTimeLabelConfig = {}) {
        super(config);

        this.config = this.mergeConfig(
            config,
            {
                timeLabelMode: PlaybackTimeLabelMode.RemainingTime,
                cssClasses: ['ad-remaining-time'],
            } as LabelConfig,
            this.config
        );
    }

    configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
        super.configure(player, uimanager);

        const isLive = player.isLive();
        if (isLive && !CONFIG.adUIEnabledOnLive) {
            this.hide();
        } else if (!isLive && !CONFIG.adUIEnabledOnVod) {
            this.hide();
        } else {
            this.show();
        }
    }
}
