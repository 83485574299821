import { CallBackFn } from '../player.d';
import { PlayerClass } from '../player';

// Register handler for retry from player ui
export function retry(this: PlayerClass) {
    this.retryCbs.forEach((cb) => {
        cb(this.error);
    });
}

// Register onRetry Call backs
export function onRetry(this: PlayerClass, callback: CallBackFn) {
    this.retryCbs.push(callback);
    return () => {
        this.retryCbs = this.retryCbs.filter((cb) => cb !== callback);
    };
}
