import {
    AudioTrackListBox,
    NavigationGroup,
    SettingsPanel as SP,
    SettingsPanelItem,
    SettingsPanelPage,
} from 'bitmovin-player-ui';
import { PlaybackSpeedListBox } from '../components/PlaybackSpeedListBox';
import { VideoQualityListBox } from '../components/VideoQualityListBox';

import { CONFIG } from 'src/config/config';
import { BackButtonController } from 'src/controllers/keyPress/backButton.controller';
import { BACK_BUTTON_RANKS } from 'src/controllers/keyPress/backButtonControllerRanks';
import { SubtitleListBox } from '../components/subtitlelistbox';

const { playbackSettingsAudioActive, playbackSettingsSpeedActive, playbackSettingsTextActive } =
    CONFIG;

export const SettingsPanel = () => {
    const videoQualityListBox = new VideoQualityListBox();
    const playbackSpeedListBox = new PlaybackSpeedListBox();
    const audioListBox = new AudioTrackListBox();
    const subtitleListBox = new SubtitleListBox();

    const settingsPanelPage = new SettingsPanelPage({
        components: [new SettingsPanelItem('Quality', videoQualityListBox)],
    });

    if (playbackSettingsAudioActive) {
        settingsPanelPage.addComponent(new SettingsPanelItem('Audio', audioListBox));
    }
    if (playbackSettingsSpeedActive) {
        settingsPanelPage.addComponent(new SettingsPanelItem('Speed', playbackSpeedListBox));
    }

    if (playbackSettingsTextActive) {
        settingsPanelPage.addComponent(new SettingsPanelItem('Subtitle', subtitleListBox));
    }

    const settingsPanel = new SP({
        components: [settingsPanelPage],
        hidden: true,
        hideDelay: CONFIG.playerUITimeout,
    });

    const handleKeyDown = () => {
        if (settingsPanel.isShown()) {
            settingsPanel.hide();
            return true;
        }
        return false;
    };

    const bbc = new BackButtonController();
    settingsPanel.onShow.subscribe(() => {
        bbc.addNewCallbackToStack(handleKeyDown, BACK_BUTTON_RANKS.playerUiSettings);
    });
    settingsPanel.onHide.subscribe(() => {
        bbc.removeCallBack();
    });

    const settingsPanelNavigationGroup = new NavigationGroup(
        settingsPanel,
        videoQualityListBox,
        playbackSpeedListBox,
        audioListBox,
        subtitleListBox
    );

    return {
        ui: settingsPanel,
        spatialNavigation: settingsPanelNavigationGroup,
    };
};
