import { useCallback, useEffect, useMemo, useState } from 'react';
import useBitmovin from 'src/lib/bitmovin/controllers/bitmovin.controller';
import { Player } from 'src/lib/bitmovin/player';
import { useStateValue } from 'src/state';
import { PlaybackType } from 'src/state/stores/dashboard/dashboard';
import {
    getAssetMetaData,
    getFreeAssetPlaybackData,
    getGeneralAssetPlaybackData,
} from 'src/state/stores/dashboard/dashboard.actions';

import { formatAssetPlaybackData } from 'src/utilities/asset';
import { checkIfGeoBlocked, checkIfKickedOnPlaybackReq } from 'src/utilities/errorValidation';
import { processImageUrl } from 'src/utilities/url';
import useAsset from './asset.controller';
import usePlaybackComplete from './playbackComplete.controller';
import usePlaybackSession from './playbackSession.controller';
import usePlaybackWatchmode from './playbackWatchmode.controller';
import useUser from './auth/user.controller';
import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';
import usePlaybackState from './playerPlaybackState.controller';
import { useKickedOnPlayback } from './kickedOnPlayback.controller';
import { useSegmentData } from './analytics/segmentData.controller';
import { type WatchMode } from 'src/lib/bitmovin';
import { BitmovinOptusPlayer } from 'src/lib/bitmovin';
import { getAppData } from 'src/lib/Analytics/Segment/utils';

// @FIXME: It's confusing, useVodPlayback() is actually also being used for Live Assets.
const useVodPlayback = () => {
    const [playbackData, setPlaybackData] = useState<any>(undefined);
    const [isGeoBlocked, setIfGeoBlocked] = useState<boolean>(false);
    const [playbackError, setPlaybackError] = useState<boolean>(false);
    const [playbackNotStarted, setPlaybackNotStarted] = useState<boolean>(false);
    const [isKickedOnPlayback, setIsKickedOnPlayback] = useState<boolean>(false);

    const { dispatch } = useStateValue();

    const { asset } = useAsset({ detailed: true });

    const [isYospaceErr, setIsYospaceErr] = useState<boolean>(
        asset?.id ? getActivePlayer().isYoSpaceError(asset.id) : false
    );

    const { isKicked, setIsKicked } = usePlaybackSession();
    const { playing } = usePlaybackState();
    usePlaybackComplete();

    const { userData, auth, user } = useUser();
    const { userId } = auth || {};

    const playback: PlaybackType | undefined = useMemo(() => {
        if (playbackData) {
            return formatAssetPlaybackData(playbackData, 'beginning');
        }
        return undefined;
    }, [playbackData]);

    const { isLive } = asset || {};

    const { viewProgress, watchMode } = usePlaybackWatchmode();

    const poster = useMemo(
        () =>
            asset?.['imageUrl_16:9'] ? processImageUrl(asset['imageUrl_16:9']) : stockBackground,
        [asset]
    );

    useEffect(() => {
        Player.setYospaceErrorCallback(() => {
            setIsYospaceErr(true);
            setPlaybackData(undefined);
        });
    }, []);

    const getPlaybackData = useCallback(() => {
        const watchModeUndefined = watchMode === null || watchMode === undefined;
        if (!asset || watchModeUndefined || playbackData !== undefined) {
            return;
        }

        setPlaybackData(null);
        // Used for Ad Holiday
        BitmovinOptusPlayer.setAssetData(asset as any);
        BitmovinOptusPlayer.setWatchMode(watchMode as WatchMode);
        Player.initTracking();

        const isAssetFree = asset.accessGroups.includes('FREE_WITHOUT_LOGIN');
        const options = isYospaceErr ? { yspSdk: false } : undefined;
        const { settings: userSettings } = userData || {};
        const getAssetPlaybackData = isAssetFree
            ? getFreeAssetPlaybackData(
                  asset.id,
                  watchMode!,
                  userSettings?.prohibitTargeting,
                  undefined,
                  undefined,
                  options
              )
            : userId
            ? getGeneralAssetPlaybackData(
                  asset.id,
                  userId,
                  watchMode!,
                  userSettings?.prohibitTargeting,
                  undefined,
                  undefined,
                  options
              )
            : null;

        if (getAssetPlaybackData) {
            dispatch(getAssetPlaybackData)
                .then((d) => {
                    const { playbackStatus } = d.playback;
                    if (playbackStatus === 'NOT_STARTED') {
                        setPlaybackNotStarted(true);
                    } else {
                        setPlaybackData(d.playback);
                        setIfGeoBlocked(false);
                    }
                })
                .catch((e) => {
                    if (checkIfGeoBlocked(e)) {
                        setIfGeoBlocked(true);
                    } else if (checkIfKickedOnPlaybackReq(e)) {
                        setIsKickedOnPlayback(true);
                    } else {
                        setPlaybackError(true);
                    }
                });
            dispatch(getAssetMetaData(asset.id));
        } else {
            setPlaybackError(true);
        }
    }, [asset, dispatch, isYospaceErr, playbackData, userData, userId, watchMode]);

    useSegmentData(asset);

    useEffect(() => {
        getPlaybackData();
    }, [getPlaybackData]);

    const retryPlayback = useCallback(() => {
        setPlaybackData(undefined);
        setPlaybackNotStarted(false);
        setIsKicked(false);
        setIfGeoBlocked(false);
        setPlaybackError(false);
        Player.unload();
    }, [setIsKicked]);

    const segmentData = useMemo(
        () => ({
            appData: getAppData(),
            userData: { ...userData, loggedIn: !!user },
            assetData: asset,
            playbackData: playbackData,
        }),
        [asset, playbackData, user, userData]
    );

    useBitmovin({
        assetId: asset?.id,
        playback,
        startTime: viewProgress || 0,
        poster,
        segmentData,
        watchMode,
        retryPlayback,
    });
    const { streamType } = playback || {};

    useKickedOnPlayback(isKickedOnPlayback);
    return {
        asset,
        isGeoBlocked,
        isKicked,
        isKickedOnPlayback,
        isLive,
        playbackError,
        playbackNotStarted,
        poster: !playing && poster,
        retryPlayback,
        streamType,
        title: asset?.title,
    };
};

export default useVodPlayback;
