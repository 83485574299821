export const KEY_PRESS_RANKS = {
    // Root 0 - 9
    // Inner Pages 10-19
    braze: 40,

    // Media BUTTON
    mediaButtons: 50,

    // Player UI 20 -29
    playerZombieKick: 80,
};
