import { FocusNode } from 'lal-lrud';
import Typography from 'src/components/Common/Typography';
import styles from './miniEPG.module.scss';
import useMiniEPG from 'src/controllers/miniEPG.controller';
import Channels from './Channels';
import MEPGProgram from './MEPGProgram/MEPGProgram';
import React from 'react';
import focusIds from 'src/constants/focusIds';

interface MiniEPGProps {
    isPlayerError: boolean;
}
const MiniEPG = (props: MiniEPGProps) => {
    const { isPlayerError } = props;
    const { changeChannel, liveAssets, onNowProgram, upNextProgram, selectedChannel } =
        useMiniEPG();

    const containerClasses = [styles.container];
    if (isPlayerError) {
        containerClasses.push(styles.playerError);
    }
    return (
        <FocusNode
            focusId={focusIds.miniEPG}
            className={containerClasses.join(' ')}
            focusedClass={styles.focused}
        >
            <Typography className={styles.sectionTitle}>Channels</Typography>
            <div className={styles.channels}>
                {liveAssets?.length ? (
                    <Channels
                        selectedChannel={selectedChannel}
                        assets={liveAssets}
                        onChange={changeChannel}
                    ></Channels>
                ) : null}
            </div>
            <div className={styles.programsContainer}>
                <div className={styles.program}>
                    <Typography className={styles.sectionTitle}>On now</Typography>
                    <MEPGProgram now program={onNowProgram}></MEPGProgram>
                </div>

                <div className={styles.program}>
                    <Typography className={styles.sectionTitle}>Up next</Typography>
                    <MEPGProgram program={upNextProgram}></MEPGProgram>
                </div>
            </div>
        </FocusNode>
    );
};

export default React.memo(MiniEPG);
