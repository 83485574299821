import React, { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import RouteTags from 'src/routes/routeTags';
import useBitmovinInit from '../../controllers/bitmovinInit.controller';
import styles from './playback.module.scss';

const PlayerScreen = () => {
    useBitmovinInit('player');
    const isVodPlayback = useMatch(RouteTags.vodPlayback);
    const isLivePlayback = useMatch(RouteTags.livePlayback);
    const isDrplayer = useMatch(RouteTags.drplayer);
    const isPlayback = isVodPlayback || isDrplayer || isLivePlayback;
    const classes = useMemo(
        () => ['forceAnim', styles.player, isPlayback ? styles.visible : undefined],
        [isPlayback]
    );
    return (
        <>
            <div id="player" className={classes.join(' ')} />
            <div id="bmui-controls" className={classes.join(' ')} />
        </>
    );
};

export default React.memo(PlayerScreen);
