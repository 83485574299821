import {
    BufferingOverlay,
    Container,
    ControlBar,
    PlaybackToggleButton,
    PlayerUtils,
    RootNavigationGroup,
    Spacer,
    SpatialNavigation,
    UIContainer,
    UIVariant,
} from 'bitmovin-player-ui';

import { BackButtonController } from 'src/controllers/keyPress/backButton.controller';
import { BACK_BUTTON_RANKS } from 'src/controllers/keyPress/backButtonControllerRanks';
import { CONFIG } from 'src/config/config';

import { ErrorMessageOverlay } from './components/ErrorMessageOverlay';
import { AdRemainingTime } from './components/AdRemainingTime';

import './styles/styles.scss';

export const getAdUI = () => {
    /*
        Basic Controls
    */
    const playbackToggleButton = new PlaybackToggleButton();

    const basicControlsContainer = new Container({
        components: [new Spacer(), playbackToggleButton, new Spacer()],
        cssClasses: ['controlbar-bottom'],
    });

    const adRemainingTime = new AdRemainingTime();

    const controlBar = new ControlBar({
        components: [adRemainingTime, basicControlsContainer],
    });

    const errorMessageOverlay = new ErrorMessageOverlay();

    const uiContainer = new UIContainer({
        hideDelay: CONFIG.playerUITimeout,

        components: [new BufferingOverlay(), controlBar, errorMessageOverlay],
        hidePlayerStateExceptions: [
            PlayerUtils.PlayerState.Prepared,
            PlayerUtils.PlayerState.Paused,
            PlayerUtils.PlayerState.Finished,
            PlayerUtils.PlayerState.Idle,
            PlayerUtils.PlayerState.Playing,
        ],
        hidden: true,
        cssClasses: ['ui-skin-ctv'],
    });

    /*
        Spatial Navigation
    */
    const rootNavigationGroup = new RootNavigationGroup(uiContainer, playbackToggleButton);

    const spatialNavigation = new SpatialNavigation(
        rootNavigationGroup,
        errorMessageOverlay.navigationGroup()
    );

    let allowExit = false;
    const tOnAction = rootNavigationGroup.onAction;
    rootNavigationGroup.onAction = (a, d, preventDefault) => {
        // Navigate Back when back button is pressed once more after ui is hidden
        if (a !== 'select') {
            uiContainer.hideUi();
            if (allowExit) {
                window.history.back();
                preventDefault();
                return;
            } else {
                allowExit = true;
                setTimeout(() => {
                    allowExit = false;
                }, 3000);
            }
        }
        if (tOnAction) {
            tOnAction(a, d, preventDefault);
        }
    };

    // Back button Handling
    const bbc = new BackButtonController();
    uiContainer.onShow.subscribe(() => {
        const handleKeyDown = () => {
            const isShown = uiContainer.getDomElement().hasClass('bmpui-controls-shown');
            if (isShown) {
                uiContainer.hideUi();
                return true;
            }
            return false;
        };
        bbc.addNewCallbackToStack(handleKeyDown, BACK_BUTTON_RANKS.playerUiMain);
    });

    uiContainer.onHide.subscribe(() => {
        bbc.removeCallBack();
    });

    const uiVariant: UIVariant = {
        ui: uiContainer,
        spatialNavigation,
        condition: (context) => {
            return context.isAd && context.adRequiresUi;
        },
    };
    return uiVariant;
};
