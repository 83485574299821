export const PLAYER_EVENT_PLAYER_READY = 'player-ready';
export const dispatchPlayerReady = () => {
    const event = new Event(PLAYER_EVENT_PLAYER_READY);
    document.dispatchEvent(event);
};

export const PLAYER_EVENT_STREAM_KICKED = 'stream-kicked';
export const dispatchStreamKick = () => {
    const event = new Event(PLAYER_EVENT_STREAM_KICKED);
    document.dispatchEvent(event);
};
