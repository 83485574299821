import { YospaceAssetType } from '@optussport/fe-bitmovin-player';
import { CONFIG } from 'src/config/config';
import {
    PlaybackType,
    WatchModeLiveType,
    WatchModeType,
} from 'src/state/stores/dashboard/dashboard';
import { isPS4 } from 'src/utilities/ps4';
import { isTizen } from 'src/utilities/tizen';
import { Player } from '../player';
import { isYspEnabled } from 'src/apis/dashboard';
import { isPS5 } from 'src/utilities/ps5';
import { isWebos } from 'src/utilities/webos';
import { SourceConfig, SourceConfigOptions, TimelineReferencePoint } from 'src/lib/bitmovin';

export const getPlayerConfig = (drmKey: string) => {
    let licenseUrl = 'https://93e30b04-drm-widevine-licensing.axprod.net/AcquireLicense';
    const playerConfigData = {
        drm: {
            retryParameters: {
                timeout: 20000,
                baseDelay: 800,
                fuzzFactor: 0.5,
                maxAttempts: 5,
                backoffFactor: 2,
            },
            servers: {},
        },
        manifest: {
            dash: {
                autoCorrectDrift: true,
            },
            retryParameters: {
                timeout: 20000,
                baseDelay: 800,
                fuzzFactor: 0.5,
                maxAttempts: 5,
                backoffFactor: 2,
            },
        },
        streaming: {
            stallSkip: 0.1,
            bufferBehind: 30,
            stallEnabled: true,
            bufferingGoal: 30,
            jumpLargeGaps: true,
            smallGapLimit: 5,
            stallThreshold: 1,
            rebufferingGoal: 2,
            retryParameters: {
                timeout: 5000,
                baseDelay: 800,
                fuzzFactor: 0.5,
                maxAttempts: 5,
                backoffFactor: 2,
            },
        },
    };
    playerConfigData.drm.servers = {
        'com.widevine.alpha': `${licenseUrl}?AxDrmMessage=${drmKey}`,
    };
    return playerConfigData;
};

export const getBitmovinSourceConfig = (
    playbackData: PlaybackType,
    startTime: number = 0,
    poster?: string,
    watchMode?: WatchModeLiveType | WatchModeType | null,
    title?: string
) => {
    console.log(playbackData);
    const { items: [item] = [], isSSAIEnabled: _isSSAIEnabled, isLive } = playbackData || {};
    const { license, url, protocol } = item || {};
    const { licenseUrl } = license || {};
    const playbackUrl = protocol === 'DASH' ? { dash: url } : { hls: url };

    const yspSdkEnabled = isYspEnabled(isLive ? 'live' : undefined);
    const isSSAIEnabled = yspSdkEnabled && _isSSAIEnabled;

    const options: SourceConfigOptions = {
        startOffset: startTime,
    };
    if (watchMode === 'startover') {
        options.startOffset = 1;
        options.startOffsetTimelineReference = TimelineReferencePoint.Start;
    }

    const sourceConfig: SourceConfig = {
        ...playbackUrl,
        // hls:"https://linear-nw.fastly.optusvideo.tv/live/EPLDR-Disk1/os1/HLS001/os1.m3u8",
        // hls: 'https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
        options,
        poster,
        // @ts-ignore
        assetType: isLive ? YospaceAssetType.LINEAR : YospaceAssetType.VOD,
        disableServiceWorker: isTizen(),
        useTizen: isTizen(),
        title,
    };

    // TODO: Improve this when we introduce PlayReady to certain Tizen OS's
    // NOTE: This is not required for Xbox & PlayReady.
    const isPlayStation = isWebos() || isPS4() || isPS5();
    const extraPlayReadyProps = isPlayStation
        ? {
              utf8message: true,
              plaintextChallenge: true,
              withCredentials: false,
              headers: {
                  'Content-Type': 'text/xml',
              },
          }
        : {};

    if (licenseUrl) {
        const widevineConfig = {
            widevine: {
                LA_URL: licenseUrl,
            },
        };

        const playReadyConfig = {
            playready: {
                LA_URL: licenseUrl,
                ...extraPlayReadyProps,
            },
        };
        const isWidevine = CONFIG.playbackDrmChoice === 'widevine';
        const drmLicenseUrl = isWidevine ? widevineConfig : playReadyConfig;

        sourceConfig.drm = {
            ...drmLicenseUrl,
        };
    }

    return { sourceConfig, isSSAIEnabled };
};

export const getActivePlayer = () => Player;
