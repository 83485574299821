import { Segment as S } from 'src/lib/bitmovin';
import { CONFIG } from 'src/config/config';

export const Segment = {
    trackApiEvent: ({ apiUrl, segmentEventName, requestParams, response }: any) => {
        if (CONFIG.isSegmentEnabled) {
            try {
                S.trackApiEvent({ apiUrl, segmentEventName, requestParams, response });
            } catch (error) {}
        }
    },
    trackApiErrorEvent: (errorStatus: any) => {
        if (CONFIG.isSegmentEnabled) {
            try {
                S.trackApiErrorEvent(errorStatus);
            } catch (error) {}
        }
    },
    trackVideoRequested: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackVideoRequested();
        }
    },
    trackZombieKickSessionFromClient: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackZombieKickSessionFromClient();
        }
    },
    trackChuckNorrisKick: () => {
        if (CONFIG.isSegmentEnabled) {
            S.trackChuckNorrisKick();
        }
    },
    handleAppKill: (errObj?: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.handleAppKill(errObj);
        }
    },
    trackPlaybackErrorDisplayed: ({ errorType }: any) => {
        if (CONFIG.isSegmentEnabled) {
            try {
                S.trackPlaybackErrorDisplayed({ errorType });
            } catch (error) {}
        }
    },
    trackPlaybackErrorInteraction: ({ interaction }: any) => {
        if (CONFIG.isSegmentEnabled) {
            S.trackPlaybackErrorInteraction({ interaction });
        }
    },
};
