import { useEffect, useMemo } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import RouteTags, { getRouteTags } from 'src/routes/routeTags';
import { useStateValue } from 'src/state';
import { getLiveAssets } from 'src/state/stores/dashboard/dashboard.actions';
import { setLastPlayedLiveAsset } from 'src/utilities/live';
import useUser from './auth/user.controller';
import { getActivePlayer } from 'src/lib/bitmovin/utilities/player';

const useLiveAsset = () => {
    const { assetId: pAssetId } = useParams();
    const isOnLivePlayback = useMatch(RouteTags.livePlayback);
    const { state, dispatch } = useStateValue();
    const { user } = useUser();

    const {
        dashboardData: { liveAssets },
    } = state;

    useEffect(() => {
        if (!liveAssets && isOnLivePlayback) {
            dispatch(getLiveAssets());
        } else {
            if (liveAssets) {
                const player = getActivePlayer();
                player.setLiveAssets(liveAssets);
            }
        }
    }, [dispatch, liveAssets, isOnLivePlayback]);

    const navigate = useNavigate();

    const asset = useMemo(
        () =>
            liveAssets && liveAssets.length ? liveAssets.find((la) => la.id === pAssetId) : null,
        [pAssetId, liveAssets]
    );

    useEffect(() => {
        if (!liveAssets || !liveAssets.length || !isOnLivePlayback) {
            return;
        }

        const { id: aId } =
            liveAssets.find((la) => la.id === pAssetId) ||
            // liveAssets.find((la) => la.id === getLastPlayedLiveAsset()) ||  // Load last played channel on launch.
            liveAssets[0];

        if (aId !== pAssetId && aId) {
            // Load the default optus sports channel upon fresh load
            navigate(getRouteTags('livePlayback', 'assetId', aId), {
                replace: true,
            });
        } else if (pAssetId) {
            setLastPlayedLiveAsset(pAssetId);
        }
    }, [isOnLivePlayback, pAssetId, liveAssets, navigate]);

    const isUserCanPlay = useMemo(() => {
        const { accessGroups: userAGs, type: userType } = user || {};
        return userAGs || userType
            ? userAGs?.includes('PREMIUM') || userType?.toUpperCase() === 'PREMIUM'
            : null;
    }, [user]);

    return { asset, isUserCanPlay };
};

export default useLiveAsset;
